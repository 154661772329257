import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { Route, processUrl } from '@portal/lib';

const OverviewPage = () => {
  const router = useRouter();

  useEffect(() => {
    const { newUrl } = processUrl({ url: Route.leadList.path });
    router.push(newUrl);
  }, []);

  return null;
};

export default OverviewPage;
